var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alias" }, [
    _c("label", [_vm._v(_vm._s(_vm.$t("Card.Alias.ChooseAlias")))]),
    _c("div", { staticClass: "alias__container" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.aliasName,
            expression: "aliasName"
          }
        ],
        ref: "aliasInput",
        attrs: { type: "text", placeholder: _vm.$t("Card.Thing.Placeholder") },
        domProps: { value: _vm.aliasName },
        on: {
          keypress: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.setAlias($event)
          },
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.aliasName = $event.target.value
          }
        }
      }),
      _c(
        "button",
        {
          staticClass: "button button--icon button--check",
          on: { click: _vm.setAlias }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fa", "check"] } })],
        1
      ),
      _c(
        "button",
        {
          staticClass: "button button--icon button--trash",
          on: { click: _vm.deleteAlias }
        },
        [_c("font-awesome-icon", { attrs: { icon: ["fas", "trash"] } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }