






























import Vue from 'vue'

export default Vue.extend({
  computed: {
    barWidth() {
      const component = this as any
      return `width: ${component.compositeSensitivity * 25}%`
    },
    compositeSensitivity() {
      return this.$store.getters['composite/compositeSensitivity'](this.$route.params.serial)
    },
    compositeSensitivityMatch() {
      return this.$store.getters['composite/compositeSensitivityMatch'](this.$route.params.serial)
    }
  },
  methods: {
    tickStyles(value: number) {
      if (value < this.compositeSensitivity) return 'backgroundColor: #dd2c2c'
    },
    setValue(value: number) {
      const serial = this.$route.params.serial
      this.$store.dispatch('composite/setCompositeSensitivity', { serial, value })
    }
  }
})
