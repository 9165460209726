var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sensitivity" }, [
    _c("label", [_vm._v(_vm._s(_vm.$t("Card.Settings.Sensitivity")))]),
    _c("div", [
      _c("div", { staticClass: "sensitivity__labels" }, [
        _c("div", [_vm._v(_vm._s(_vm.$t("Card.Settings.Low")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("Card.Settings.Medium")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("Card.Settings.High")))])
      ]),
      _c("div", { staticClass: "sensitivity__container" }, [
        _c("span", { staticClass: "sensitivity__bar sensitivity__bar--full" }),
        _c("span", { staticClass: "sensitivity__bar", style: _vm.barWidth }),
        _c(
          "div",
          { staticClass: "sensitivity__ticks" },
          _vm._l(5, function(button, index) {
            return _c("button", {
              key: index,
              staticClass: "sensitivity__tick",
              class: {
                "sensitivity__tick--active": index === _vm.compositeSensitivity
              },
              style: _vm.tickStyles(index),
              on: {
                click: function($event) {
                  return _vm.setValue(index)
                }
              }
            })
          }),
          0
        )
      ]),
      !_vm.compositeSensitivityMatch
        ? _c("p", { staticClass: "sensitivity__message" }, [
            _vm._v(" " + _vm._s(_vm.$t("Card.Settings.Updating")) + " ")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }