





















import { Component, Vue } from 'vue-property-decorator'
import SelectClient from '@/components/Select/Client.vue'

@Component({
  components: {
    SelectClient
  }
})
export default class Alias extends Vue {
  private aliasName: string = ''

  setAlias(): void {
    if (this.aliasName.length === 0) return

    const serial = this.$route.params.serial
    const alias = this.aliasName.replace(/\s/g, '_')
    this.$store.dispatch('composite/putCompositeAlias', { serial, alias })
  }

  deleteAlias(): void {
    const serial = this.$route.params.serial
    this.$store.dispatch('composite/deleteCompositeAlias', { serial })
    this.aliasName = ''
  }
}
