




















import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

import Alias from '@/components/Card/Settings/Alias.vue'
import Sensitivity from '@/components/Card/Settings/Sensitivity.vue'
import Header from '@/components/Card/Things/Header.vue'
import SelectClient from '@/components/Select/Client.vue'
import { AgentModeEnum } from '@/store/modules/composite'
import { IStateThing } from '@/store/modules/thing'
import { REFRESH_THING_STATE_MS } from '@/utils/constant'

@Component({
  components: {
    Alias,
    Header,
    SelectClient,
    Sensitivity
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    })
  }
})
export default class Index extends Vue {
  private clientsLoad: boolean = false
  private timeout: number = 0
  private AgentModeEnum: typeof AgentModeEnum = AgentModeEnum

  get compositeConnected() {
    return this.$store.getters['composite/compositeConnected'](this.$route.params.serial)
  }

  get compositeStatus() {
    return this.$store.getters['composite/compositeStatus'](this.$route.params.serial)
  }

  @Watch('compositeConnected', { immediate: true })
  refreshClients() {
    const serial = this.$route.params.serial
    if (!this.compositeConnected) return

    this.$store.dispatch('client/getCompositeConnectedClients', { serial }).finally(() => {
      this.clientsLoad = true
    })
  }

  getComposites() {
    const hasSerial = this.$store.state.thing.serial !== null
    if (!hasSerial) return

    const serial = this.$route.params.serial
    this.$store.dispatch('composite/getCompositeStatus', { serial }).finally(() => {
      this.timeout = setTimeout(this.getComposites, REFRESH_THING_STATE_MS)
    })
  }

  @Watch('serial')
  clearComponent() {
    clearTimeout(this.timeout)
    this.$store.commit('thing/resetThing')
    this.$store.commit('composite/resetComposite')
    this.$store.commit('diagnostic/resetCompositeDiagnostic')
    this.$store.commit('client/resetCompositeConnectedClients')
    this.getComposites()
  }

  mounted() {
    clearTimeout(this.timeout)
    this.getComposites()
    this.refreshClients()
  }

  beforeDestroy() {
    clearTimeout(this.timeout)
  }
}
