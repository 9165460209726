












import Vue from 'vue'
import { IStateClient } from '@/store/modules/client'

export default Vue.extend({
  props: {
    compositeSerial: {
      type: String,
      required: true
    }
  },
  computed: {
    compositeClientOptions() {
      return this.$store.getters['client/compositeClientOptions'](this.compositeSerial)
    },
    compositeClientReported() {
      return this.$store.getters['composite/compositeClientReported'](this.compositeSerial)
    }
  },
  methods: {
    setWifiClient(client: string) {
      const serial = this.compositeSerial
      this.$store.dispatch('client/putSelectedClient', { serial, client })
    }
  }
})
