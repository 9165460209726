






import Vue from 'vue'
import Settings from '@/components/Card/Settings/Index.vue'

export default Vue.extend({
  components: {
    Settings
  }
})
